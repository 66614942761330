import {useState} from 'react'
import styled from 'styled-components'
// eslint-disable-next-line
import {Navigation} from '../../../../routes/nav'
import NavItem from './NavItem'

const StlWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 480px) {
    justify-content: center;
    align-items: center;
    & .nav-item-link {
      text-decoration: none;
      color: #ffffff;
    }
  }
  & .nav-items {
    display: flex;
    & .nav-item {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 110px;
      height: 54px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      & .nav-item-link {
        text-decoration: none;
        color: rgba(103, 114, 148, 0.8);
      }
      &.nav-item-active {
        background: #ffffff;
        & .nav-item-link {
          color: #0065f2;
        }
      }
    }
    @media screen and (max-width: 480px) {
      height: 83px;
      align-items: center;
      background: white;
    }
  }
  & .breadcrumbs {
    background: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #222222;
    padding: 8px;
    padding-bottom: 24px;
    @media screen and (max-width: 480px) {
      display: none;
    }
    & a {
      color: #222222;
      text-decoration: none;
    }
    & .base-icon {
      vertical-align: bottom;
      width: 15px;
      margin: 0px 5px;
    }
  }
`

/**
 *
 * @param {{ navigations: [Navigation] }} props
 * @returns
 */
export default function NavBar({navigations}) {
  const dashboardNav = navigations.find(({name}) => name === 'Dashboard')
  const [breadcrumb, setBreadcrumb] = useState('')
  const {children} = dashboardNav

  return (
    <StlWrap className='navbar'>
      <div className='nav-items'>
        {children.map((navigation, i) => (
          <NavItem
            navigation={navigation}
            key={i}
            setBreadcrumb={setBreadcrumb}
          />
        ))}
      </div>
      <div className='breadcrumbs'>
        {breadcrumb ? breadcrumb : <span>&nbsp;</span>}
      </div>
    </StlWrap>
  )
}
