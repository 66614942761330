import {NavLink, Link} from 'react-router-dom'
import BIcon from '../../../base/Icon'
// eslint-disable-next-line
import {Navigation} from '../../../../routes/nav'
import {genClass, genId} from '../../../../shared/utils'
import {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'

/**
 *
 * @param {{ navigation: Navigation, isChild: false, breadcrumbs: [{ text: '', name: '', to: '' }] }} props
 * @returns
 */
function NavItem({className, navigation, isChild = false, setBreadcrumb}) {
  const {
    hideNav,
    exact,
    text,
    icon,
    path,
    children,
    pathChildren,
    breadcrumbs,
  } = navigation
  const [isActive, setActive] = useState(false)
  const [isChildActive, setChildActive] = useState(false)
  const hasChild = !isChild && children && children.length
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (isActive && setBreadcrumb instanceof Function) {
      setBreadcrumb(genBreadcrumb(breadcrumbs))
    }
  }, [isActive])

  if (hideNav) return ''

  const cls = genClass('nav-item', className, {
    'nav-item-active': isActive,
    'nav-item-has-children': hasChild,
    'nav-item-child': isChild,
    'nav-item-child-active': isChildActive,
  })
  const navLinkIsActiveHandler = (match, location) => {
    const childActive = !isChild && location.pathname.startsWith(`${path}/`)
    const active =
      !childActive &&
      (!!match || checkPathChildren(pathChildren, location.pathname))
    setTimeout(() => {
      if (mounted.current) {
        active !== isActive && setActive(active)
        childActive !== isChildActive && !isChild && setChildActive(childActive)
      }
    })
    return active
  }

  return (
    <div className={cls}>
      <NavIcon
        to={path}
        exact={!!exact}
        className='nav-item-link'
        isActive={navLinkIsActiveHandler}
        onClick={() => setActive(true)}
      >
        {icon ? (
          <BIcon icon={icon || 'arrow-right'} width='22' height='22' />
        ) : (
          ''
        )}
        <span>{navigation.name || text}</span>
      </NavIcon>
      {!isChild ? (
        <div className='nav-item-children'>
          {(children || []).map((child, i) => (
            <NavItem navigation={child} key={i} isChild />
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

function checkPathChildren(pathChildren, pathname) {
  if (!pathChildren) return false
  if (pathChildren instanceof Array)
    return pathChildren.some((pathChild) =>
      checkPathChildren(pathChild, pathname)
    )
  return pathname.includes(pathChildren)
}

export default NavItem

function genBreadcrumb(breadcrumbs) {
  const links = (breadcrumbs || []).map(({text, name, to}) => {
    return (
      <Link key={genId()} to={to}>
        {text}
      </Link>
    )
  })
  if (links.length > 1) {
    for (let i = links.length - 1; i >= 1; i--) {
      links.splice(i, 0, <BIcon key={genId()} icon='chevrons-right' />)
    }
  }
  return links
}

const NavIcon = styled(NavLink)`
  & > :first-child {
    display: none;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    & > :first-child {
      display: block;
    }
  }
`
