import styled from 'styled-components'
import DashboardLayout from '../components/layouts/DashboardLayout'

const Styled = styled.div(`
  width: 100vw;
  height: 100vh;
`)

export default function EmptyPage({isDashboard = false}) {
  return isDashboard ? (
    <DashboardLayout className='empty-page' />
  ) : (
    <Styled className='empty-page' />
  )
}
