export default function Avatar(props) {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.3286 12.8286C17.4724 11.9287 18.3072 10.6946 18.717 9.29816C19.1268 7.90168 19.0911 6.4122 18.615 5.03694C18.1388 3.66168 17.2459 2.46902 16.0603 1.62489C14.8748 0.780766 13.4556 0.327148 12.0002 0.327148C10.5449 0.327148 9.12571 0.780766 7.94016 1.62489C6.75461 2.46902 5.86164 3.66168 5.38549 5.03694C4.90933 6.4122 4.87367 7.90168 5.28346 9.29816C5.69325 10.6946 6.52812 11.9287 7.67191 12.8286C5.712 13.6138 4.00191 14.9161 2.72395 16.5968C1.44599 18.2775 0.648064 20.2734 0.41524 22.3719C0.398387 22.5251 0.411876 22.6801 0.454937 22.8281C0.497997 22.9761 0.569786 23.1142 0.666204 23.2344C0.860929 23.4773 1.14415 23.6329 1.45357 23.6669C1.76299 23.7009 2.07326 23.6107 2.31612 23.4159C2.55898 23.2212 2.71454 22.938 2.74857 22.6286C3.00476 20.348 4.09221 18.2417 5.80316 16.7122C7.51412 15.1826 9.72862 14.3371 12.0236 14.3371C14.3185 14.3371 16.533 15.1826 18.244 16.7122C19.9549 18.2417 21.0424 20.348 21.2986 22.6286C21.3303 22.9152 21.4671 23.18 21.6825 23.3717C21.898 23.5635 22.1768 23.6686 22.4652 23.6669H22.5936C22.8994 23.6317 23.1789 23.4771 23.3712 23.2367C23.5635 22.9963 23.653 22.6896 23.6202 22.3836C23.3863 20.2791 22.5841 18.278 21.2996 16.5948C20.015 14.9116 18.2966 13.6096 16.3286 12.8286V12.8286ZM12.0002 12.0002C11.0773 12.0002 10.175 11.7265 9.40758 11.2138C8.64015 10.701 8.04201 9.97214 7.6888 9.11942C7.33559 8.26669 7.24318 7.32838 7.42324 6.42314C7.60331 5.51789 8.04776 4.68637 8.70041 4.03373C9.35305 3.38108 10.1846 2.93663 11.0898 2.75656C11.9951 2.5765 12.9334 2.66891 13.7861 3.02212C14.6388 3.37533 15.3677 3.97347 15.8804 4.7409C16.3932 5.50833 16.6669 6.41058 16.6669 7.33356C16.6669 8.57124 16.1752 9.75822 15.3001 10.6334C14.4249 11.5086 13.2379 12.0002 12.0002 12.0002Z'
        fill='currentColor'
        fillOpacity='0.37'
      />
    </svg>
  )
}
