import createStore from './createStore'
import {addAuthToken, removeAuthToken} from '../shared/apiHelper'
import {
  login,
  refreshToken,
  sendMailForgotPassword,
  resetPassword,
} from '../shared/apiHelper/auth'
const TIMEOUT_SAVE_TOKEN = 10 * 60 * 1000 // 10mins

let calledInit = false

const initialValue = {
  initing: true,
  authenticated: false,
  authData: {
    accountId: '',
    token: '',
  },
}
const ACTIONS = {
  INIT: 'INIT',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SEND_MAIL_FORGOT_PASSWORD: 'SEND_MAIL_FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
}

/**
 *
 * @param {initialValue} state
 * @param {{
 *  type: '',
 *  payload: {}
 * }}} action
 */
const reducer = (state, {type, payload}) => {
  switch (type) {
    case ACTIONS.INIT: {
      return {
        initing: false,
        ...payload,
      }
    }
    case ACTIONS.LOGIN: {
      return {
        ...state,
        initing: false,
        authenticated: true,
        authData: payload,
      }
    }
    case ACTIONS.LOGOUT: {
      return {
        initing: false,
        authenticated: false,
        authData: {
          accountId: '',
          token: '',
        },
      }
    }
    default:
      return state
  }
}

const actions = {
  init: (dispatch) => async () => {
    if (calledInit) return
    calledInit = true
    const authData = await validateLocalToken()
    const payload = authData
      ? {authenticated: true, authData}
      : {
          authenticated: false,
          authData: {
            accountId: '',
            token: '',
          },
        }
    if (authData) addAuthToken(authData.token)
    dispatch({
      type: ACTIONS.INIT,
      payload,
    })
  },
  login: (dispatch) => async (data, saveAuth) => {
    const authData = await login(data)
    if (!authData) throw new Error('Admin is not exist!')

    saveToken(authData.token, saveAuth)
    addAuthToken(authData.token)
    dispatch({
      type: ACTIONS.LOGIN,
      payload: authData,
    })
  },
  logout: (dispatch) => () => {
    clearToken()
    removeAuthToken()
    dispatch({
      type: ACTIONS.LOGOUT,
    })
  },
  sendMailForgotPassword:
    (dispatch) =>
    ({email}) => {
      return sendMailForgotPassword({email})
    },
  resetPassword:
    (dispatch) =>
    ({email, code, newPassword}) => {
      return resetPassword({email, code, newPassword})
    },
}

async function validateLocalToken() {
  const dataAuthEncoded = localStorage.getItem('_')
  try {
    const {token: localToken, saveTo} = JSON.parse(atob(dataAuthEncoded))
    if (!localToken) return null
    if (saveTo && saveTo < Date.now()) return null
    const authData = await refreshToken(localToken)
    saveToken(authData.token, !saveTo)
    return authData
  } catch (err) {
    clearToken()
    console.log(err)
    return null
  }
}

function saveToken(token, saveAuth = true) {
  const dataAuth = {
    token,
    saveTo: saveAuth ? null : Date.now() + TIMEOUT_SAVE_TOKEN,
  }
  const dataAuthEncoded = btoa(JSON.stringify(dataAuth))
  localStorage.setItem('_', dataAuthEncoded)
}

function clearToken() {
  localStorage.removeItem('_')
}

export const {Provider: AuthProvider, Context: AuthContext} = createStore(
  reducer,
  actions,
  initialValue
)
