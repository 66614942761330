import styled from 'styled-components'
import {genClass} from '../../../../shared/utils'
import BIcon from '../../../base/Icon'

const StlProfileGroup = styled.div`
  & > :first-child {
    display: none;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 60px;
    padding-top: 20px;
    & > :first-child {
      display: flex;
      position: absolute;
      width: 56px;
      height: 56px;
      background: rgba(0, 94, 255, 0.24);
      border-radius: 28px;
      left: 20px;
      top: 25px;
      justify-content: center;
      align-items: center;
    }
    & > span {
      padding-left: 30px;
    }
  }
`

const ProfileGroup = ({className, children}) => {
  return (
    <StlProfileGroup className={genClass(className, 'profile-group')}>
      <div>
        <BIcon icon='avatar' />
      </div>
      {children}
    </StlProfileGroup>
  )
}

export default ProfileGroup
