import {forwardRef} from 'react'
import {genClass} from '../../../shared/utils'
import {Link} from 'react-router-dom'
import BIcon from '../Icon'
import './index.css'

const COLORS = ['primary', 'white', 'black', 'red', 'green']

const BBtn = forwardRef(
  (
    {
      className,
      children,
      type = 'button',
      color = 'primary',
      to,
      outline = false,
      large = false,
      size = 'lg',
      disabled = false,
      iconLeft,
      ...rest
    },
    ref
  ) => {
    const cls = genClass(className, 'base-btn', {
      [`bb-${color}`]: COLORS.includes(color),
      'bb-large': !!large,
      'bb-outline': !!outline,
      'bb-size-lg': size === 'lg',
      'bb-size-md': size === 'md',
      'bb-size-sm': size === 'sm',
      'bb-disabled': disabled,
      'bb-icon-left': !!iconLeft,
    })
    return (
      <button
        className={cls}
        type={type === 'link' ? 'button' : type}
        disabled={disabled}
        {...rest}
        {...(ref ? {ref} : {})}
      >
        {to ? (
          <Link to={to} className='bb-link'>
            {children}
          </Link>
        ) : (
          ''
        )}
        {iconLeft ? <BIcon className='icon-left' icon={iconLeft} /> : ''}
        {!to ? children : ''}
      </button>
    )
  }
)

export default BBtn
