import React, {lazy, Suspense} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'

import Empty from '../pages/Empty'

const Business = lazy(() => import('../pages/Business'))
const Searching = lazy(() => import('../pages/Searching'))
const Activities = lazy(() => import('../pages/Activities'))

const Login = lazy(() => import('../pages/Login'))
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'))

export class Navigation {
  name = ''
  type = ''
  path = ''
  redirect = ''
  icon = '' // prop use to gen navbar
  text = '' // prop use to gen navbar
  hideNav = false // prop use to gen navbar
  exact = false
  cpn = () => {}
  children = [
    {
      name: '',
      path: '',
      text: '',
      pathChildren: '',
      hideNav: false,
      cpn: () => {},
    },
  ]
}

const adminNavigations = [
  {
    name: 'Auth',
    path: '/auth',
    hideNav: true,
    exact: true,
    redirect: '/auth/login',
    children: [
      {
        name: 'Login',
        path: '/auth/login',
        exact: true,
        cpn: Login,
      },
      {
        name: 'Forgot Password',
        path: '/auth/forgot-password',
        exact: true,
        cpn: ForgotPassword,
      },
      {
        name: 'Forgot Password',
        path: '/auth/reset-password',
        exact: true,
        cpn: ForgotPassword,
      },
    ],
  },
  {
    name: 'Home',
    path: '/',
    hideNav: true,
    exact: true,
    redirect: '/dashboard',
  },
  {
    name: 'Dashboard',
    type: 'dashboard',
    path: '/dashboard',
    exact: true,
    redirect: '/dashboard/business',
    children: [
      {
        name: '사업장',
        path: '/dashboard/business',
        exact: true,
        cpn: Business,
        icon: 'home',
        breadcrumbs: [
          {
            name: 'Business',
            to: '/dashboard/business',
            text: '사업장',
          },
          {
            name: 'Service Status',
            to: '/dashboard/business',
            text: '서비스현황',
          },
        ],
      },
      {
        name: '조회',
        path: '/dashboard/search',
        exact: true,
        cpn: Searching,
        icon: 'eye',
        breadcrumbs: [
          {
            name: 'Search',
            to: '/dashboard/search',
            text: '조회',
          },
          {
            name: 'Search CDR',
            to: '/dashboard/search',
            text: 'CDR 조회',
          },
        ],
      },
      {
        name: '운영',
        path: '/dashboard/activity',
        exact: true,
        icon: 'activity',
        cpn: Activities,
      },
    ],
  },
]

/**
 * @summary Generated React routes from navigations infos
 * @param {[Navigation]} navigations
 */
function genRoutes(navigations) {
  function _flat(navigations) {
    const flatArray = []
    for (const nav of navigations) {
      flatArray.push(nav)
      for (const child of nav.children || []) {
        flatArray.push(child)
      }
    }
    return flatArray
  }
  return <Switch>{_flat(navigations).map(navigationToRoute)}</Switch>
}

/**
 *
 * @param {Navigation} navigation
 * @param {any} key
 */
function navigationToRoute(navigation, key) {
  const {type, path, name, exact, redirect, cpn} = navigation
  if (!path) return ''
  return (
    <Route
      key={key}
      name={name}
      exact={!!exact}
      path={path}
      render={renderRouteCpn(cpn, redirect, key, type)}
    ></Route>
  )
}

function renderRouteCpn(cpn, redirect, key, type) {
  if (redirect) {
    return () => <Redirect to={redirect} />
  }
  const Component = cpn || Empty
  return () => (
    <Suspense fallback={<Empty isDashboard={type === 'dashboard'} />} key={key}>
      <Component></Component>
    </Suspense>
  )
}

export {adminNavigations, genRoutes, navigationToRoute}
