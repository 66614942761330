import {genClass} from '../../../shared/utils'

import ArrowRight from 'react-feather/dist/icons/arrow-right'
import ChevronUp from 'react-feather/dist/icons/chevron-up'
import ChevronDown from 'react-feather/dist/icons/chevron-down'
import ChevronLeft from 'react-feather/dist/icons/chevron-left'
import ChevronRight from 'react-feather/dist/icons/chevron-right'
import ChevronsLeft from 'react-feather/dist/icons/chevrons-left'
import ChevronsRight from 'react-feather/dist/icons/chevrons-right'
import Download from 'react-feather/dist/icons/download'
import Upload from 'react-feather/dist/icons/upload'
import Remove from 'react-feather/dist/icons/file-minus'
import CameraPlus from './camera-plus'
import SearchPlus from './search-plus'
import Trash2 from 'react-feather/dist/icons/trash-2'
import X from 'react-feather/dist/icons/x'
import eye from 'react-feather/dist/icons/eye'
import eyeOff from 'react-feather/dist/icons/eye-off'
import Export from './export'
import Home from 'react-feather/dist/icons/home'
import Activity from 'react-feather/dist/icons/activity'
import MoreVertical from 'react-feather/dist/icons/more-vertical'
import Avatar from './avatar'
import LogOut from 'react-feather/dist/icons/log-out'
import Filter from 'react-feather/dist/icons/filter'
import Edit from 'react-feather/dist/icons/edit'

/**
 * need to manual import & define each icon used here to reduce bundle size
 */
const ICONS = {
    'arrow-right': ArrowRight,
    'camera-plus': CameraPlus,
    'trash-2': Trash2,
    x: X,
    'chevron-up': ChevronUp,
    'chevron-down': ChevronDown,
    'chevron-left': ChevronLeft,
    'chevron-right': ChevronRight,
    'chevrons-left': ChevronsLeft,
    'chevrons-right': ChevronsRight,
    eye: eye,
    'search-plus': SearchPlus,
    export: Export,
    download: Download,
    upload: Upload,
    remove: Remove,
    'eye-off': eyeOff,
    home: Home,
    activity: Activity,
    'more-vertical': MoreVertical,
    avatar: Avatar,
    'log-out': LogOut,
    filter: Filter,
    edit: Edit,
}

/**
 *
 * @returns
 */
const BIcon = ({className, icon = '', onClick = undefined, ...rest}) => {
    const IconCpn = ICONS[icon]
    if (!IconCpn) throw new Error(`Undefined icon name '${icon}'`)

    return (
        <IconCpn
            className={genClass('base-icon', className, {
                'bi-has-click': !!onClick,
            })}
            onClick={onClick}
            {...rest}
        ></IconCpn>
    )
}

export default BIcon
