import styled from 'styled-components'
import Navbar from './Navbar'
import {adminNavigations} from '../../../routes/nav'
import {genClass, to4Digit} from '../../../shared/utils'
import {AuthContext} from '../../../store/auth'
import {useContext, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import BBtn from '../../base/Btn'
import ProfileGroup from './ProfileGroup'
import AppLogo from './AppLogo'
import BIcon from '../../base/Icon'

const StlWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-width: 100vw;
  min-height: 100vh;
  height: 100vh;
  padding: 20px 32px;
  background: #e5e5e5;
  @media screen and (max-width: 480px) {
    padding: 0;
  }
  & .dl-top-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    & .app-logo {
      display: flex;
      align-items: center;
      @media screen and (max-width: 480px) {
        display: none;
      }
      & span:first-child {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        color: #0065f2;
        margin-right: 34px;
      }
      & span:last-child {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 37px;
        letter-spacing: -0.4px;
        color: #4d4d4d;
      }
    }
  }
  & .dl-nav-wrap {
    @media screen and (max-width: 480px) {
      width: 100%;
      position: fixed;
      bottom: 0;
      background-color: #ffffff;
      z-index: 1;
    }
  }
  & .dl-content {
    background: #ffffff;
    height: 100%;
    width: 100%;
    overflow-x: auto;
  }
`
const ProfileGroups = styled(ProfileGroup)`
  & > .icon-log-out {
    display: none;
  }

  @media screen and (max-width: 480px) {
    position: relative;
    & > .icon-log-out {
      display: block;
      position: absolute;
      height: 16px;
      width: 16px;
      left: 90px;
      top: 30px;
      pointer-events: none;
    }
    & > .button-log-out {
      padding-left: 45px;
    }
  }
`

const DashboardLayout = ({className, children}) => {
  const {state, init: initAuth} = useContext(AuthContext)
  const history = useHistory()
  const accountId = state.authData?.accountId || 0

  useEffect(() => {
    initAuth()
  }, [])

  if (state.initing) return ''

  if (!state.initing && !state.authenticated) {
    setTimeout(() => history.push('/auth/login'))
    return ''
  }

  return (
    <StlWrap className={genClass(className, 'dashboard-layout')}>
      <div className='dl-top-bar'>
        <AppLogo>
          <span>LOGO</span>
          <span>NAME</span>
        </AppLogo>
        <ProfileGroups>
          <BBtn className='button-log-out' color='black' to='/auth/login'>
            {'로그 아웃' /* signout */}
          </BBtn>
          <BIcon className='icon-log-out' icon='log-out' />
          <span>{to4Digit(accountId)}</span>
        </ProfileGroups>
      </div>
      <div className='dl-nav-wrap'>
        <Navbar navigations={adminNavigations} />
      </div>
      <div className='dl-content'>{children}</div>
    </StlWrap>
  )
}

export default DashboardLayout
